.importResultsContainer {
  margin-left: 24px;
}

.header {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.header.visible {
  margin-bottom: 16px;
}

.header span:first-child {
  font-weight: 600;
  font-size: 16px;
  margin-right: 8px;
}

.header .arrowDown, .header .arrowRight {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid black;
  transition: transform 0.3s ease;
}

.header .arrowRight {
  transform: rotate(-90deg);
}

.resultsList {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  overflow: hidden;
  animation: slideDown 0.3s ease forwards;
  margin-bottom: 16px;
}

.resultsList.hide {
  animation: slideUp 0.3s ease forwards;
}

@keyframes slideDown {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 1000px;
  }
}

@keyframes slideUp {
  from {
    opacity: 1;
    max-height: 1000px;
  }
  to {
    opacity: 0;
    max-height: 0;
  }
}
