.headerContainer {
  min-width: 100%; /* задаем ширину в 100% от ширины экрана */

  margin: 0 auto;
  white-space: nowrap;
  height: 55px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  background: #fff;
  font-size: 13px;
  box-shadow: 0 -2px 14px #828282;
  z-index: 10;

  > div {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: 1200px) {
    width: 1400px;
  }
}
.headerItem {
  margin: 0 15px;
  color: #000c17;
  cursor: pointer;
}

.userInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  > span {
    margin-left: 15px;
  }
}



