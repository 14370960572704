html {
  font-size: 0.75rem;
}

// antd overriding styles
.ant-layout {
  background-color: #ffffff !important;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border: none !important;
}
.universal-table {
  position: relative;
  box-sizing: border-box;
}

.universal-table-settings {
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 8px 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-direction: column;
  padding: 7px 15px;
}

.table-expanded-indent {
  height: 1px;
  float: left;
}
.table-expanded-loading,
.table-expanded-icon {
  margin-right: 8px;
  font-size: 17px;
  float: left;
  line-height: 0;
  margin-top: 0 !important;
}

.table-expanded-icon {
  cursor: pointer;
}

.table-expanded-icon.spaced {
  visibility: hidden;
  cursor: default;
}

.table-header-cell {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 7px 0;
  width: 100%;
  justify-content: flex-end;
}

.table-header-cell > div:nth-child(1) {
  height: 100% !important;
}

.BaseTable__table-main .BaseTable__header {
  background-color: transparent;
}
.BaseTable__header-row {
  background-color: transparent;
}
.BaseTable__row--frozen {
  background-color: #f3f3f3;
}
.table-header-sort {
  display: inline;
  cursor: pointer;
}
.table-header-sort-right.sort-asc::after,
.table-header-sort-right.sort-desc::after {
  left: unset !important;
  right: 10px !important;
}

.table-header-sort.sort-asc::after,
.table-header-sort.sort-desc::after {
  position: absolute;
  top: 6px;
  left: 15px;
  color: #1890ff;
}
.table-header-sort.sort-asc::after {
  content: "▲";
}
.table-header-sort.sort-desc::after {
  content: "▼";
}
.table-header-cell-question {
  font-size: 12px;
  /*color: #ccc;*/
  margin-right: 3px;
  cursor: pointer;
}
.table-header-cell-question > .anticon {
  display: inline-flex;
}
.table-overlay {
  height: calc(100% + 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
}
.table-row-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.table-row-empty .ant-empty-normal {
  margin: 0;
}
.table-header-cell-buttons {
  padding-bottom: 12px;
  opacity: 0.5;
  justify-content: flex-end;
}
.table-header-cell-buttons:hover {
  opacity: 1;
}

.listpicker-item {
  overflow-y: hidden;
  margin-bottom: 7px;
}

.listpicker-item.listpicker-item-hide {
  overflow-y: hidden;
  height: 0;
  margin-bottom: 0;
}

.picker-counter-element {
  background-color: #ff3f2f;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
}
.orange-switcher.ant-switch-checked {
    background: #ec7a28 !important;
}
.modal-settings .drag-item {
  box-sizing: border-box;
  display: inline-block;
  line-height: 28px;
  margin: 0 3px 4px 0;
  padding-right: 20px;
  border: solid 1px #ddd;
  border-radius: 3px;
  white-space: nowrap;
  cursor: move;
  position: relative;
  width: 100%;
}

.modal-settings .drag-handler .anticon {
  margin: 0 3px;
}

.modal-settings .drag-handler .drag-label {
  display: inline-block;
  /*max-width: 250px;*/
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 13px;
  vertical-align: middle;
  font-size: 14px;
}

.modal-settings .drag-remove {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 6px;
  border-left: #ddd solid 1px;
  cursor: pointer;
  opacity: 0.5;
  height: 30px;
  line-height: 24px;
}

.modal-settings .drag-remove:hover {
  opacity: 1;
}

.modal-settings .drag-remove .anticon {
  padding-top: 8px;
}

.BaseTable__row-cell {
  padding: unset !important;
  padding-left:2px !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  position: relative;
}

.ant-menu {
  font-size: 12px !important;
}

.ant-select-selection-item, .ant-select-item-option-content {
  display: flex;
  align-items: center;

  .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// main styles

.layout__content {
  height: 100%;
  width: 100%;
  margin: 10px;
}

.layout__nav-sider {
  border-right: 1px solid #e5e5e5;
  padding-top: 25px;
}

.page-wrapper {
  padding: 20px 15px;
}

.page-content {
  padding: 10px 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.page-title-tooltip-text {
  color: #000c17 !important;
  overflow: auto;
  max-height: 300px;
  max-width: 800px;
  width: 100%;
}

.page-title-tooltip .ant-tooltip-inner {
  padding: 20px !important;
  border-radius: 5px !important;
  max-height: 500px;
  max-width: 800px;
  width: 100%;
}

.page-title-tooltip {
  max-width: 500px !important;
}

.global-loading-container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.description-split {
  display: flex;
  flex-direction: row;
  align-items: center;

  > div {
      border-left: 1px solid #f7f7f7;
      margin: 0 20px;
      padding: 20px;
  }
}

.un-form__wrapper {
  margin: 30px 0;

  .ant-form-item {
    margin-bottom: 18px !important;
  }
}

.blueButton {
  background-color: #00a2e8;
  color: #fff;
}
.blueButton.disabled {
  background-color: rgba(0, 0, 0, 0.04);
}
.children-list-item {
  padding-left:38px !important;
  height: 28px !important;
}
.BaseTable {
  font-size:14px !important;
}

.BaseTable__header-row,.BaseTable__header-cell-text {
  font-weight: 600 !important;
}
.ant-modal-content {
  @media (max-width: 678px) {
    min-width: 600px;
  }
}

@media (max-width: 678px) {
  .page-wrapper {
    min-width: 946px;
  }
}
.ant-table-container {
  min-width:768px;
}

@media only screen and (max-width: 576px) {
  .ant-pagination .ant-pagination-options {
    padding-top: 10px;
    display: block;
  }
}
.red {
  background-color:red !important;
}

.table-row-error-with-select {
  background-color: #FEEEEE !important;

  & .ant-table-cell {
    background-color: #FEEEEE !important;
  }
}
.hide-datepicker-placeholder {
  input[date-range="end"]::placeholder {
    opacity: 0;
  }
}
