
.logoWrapper {
  padding-left: 28px;
  width: 190px;
  padding-bottom: 20px;
  z-index: 9;
  margin-top: -6px;

  > a {
    color: #fff !important;
    font-size: 22px;
  }
}
