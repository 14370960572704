.active-card {
  background-color: #e6f7ff;
  border-color: #1890ff;
  .counter {
    background:#fbfdff;
  }
}
.counter {
  margin-left:6px;
  padding:4px;
  border-radius:4px;
  background:#f1f6fb;
}
.custom-card{
  &:hover {
    cursor:pointer;
    background-color: #e6f7ff;
    border-color: #1890ff;
    .counter {
      background:#fbfdff;
    }
  }
}
